import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useContext
} from 'react';

import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@material-ui/core';

import {
  SECONDARY_COLOR,
  ATALHO_AVANCAR, 
  ATALHO_VOLTAR,
  SECONDARY_COLOR_OFFLINE
} from '../../../utils'
import { TextInput } from '../../Inputs';
import InputBase from '@material-ui/core/InputBase';
import { AppContext } from '../../../app/context';


const FECHAR_VENDA_ACTION = ATALHO_AVANCAR;
const CANCELAR_VENDA_ACTION = ATALHO_VOLTAR;
const ESC_ACTION = 'Escape';

const DialogoConfirmacao = forwardRef(({ handleClose2 = () => {}, handleConfirma, isInput = false }, ref) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [codigo, setCodigo] = useState(0);

  const { app: { modoOffline } } = useContext(AppContext);


  useImperativeHandle(ref, () => ({
    handleOpen(titleNew, contentNew, codigoNew) {
      setTitle(titleNew);
      setContent(contentNew);
      setOpen(true);
      setCodigo(codigoNew);
    },
  }));

  const handleClose = () => {
    setOpen(false);
    handleClose2()
  };

  const handleSalvar = () => {
    setOpen(false);
    handleConfirma(codigo);
  };

  function handleKey(action, name) {
    switch (name) {
      case FECHAR_VENDA_ACTION:
        handleSalvar();
        break;
      case CANCELAR_VENDA_ACTION:
        handleClose();
        break;
      case ESC_ACTION:
        handleClose();
        break;
      default:
        break;
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle 
          id="alert-dialog-title"
          style={{ 
            color: 'white', 
            backgroundColor: modoOffline ? SECONDARY_COLOR_OFFLINE : SECONDARY_COLOR, 
            textAlign: 'center' 
          }}
        >
          {title}
        </DialogTitle>
        <DialogContent style={{
          padding: '10px',
          margin: 0
        }}>
            {isInput ? <InputBase
            label=""
            value={content}
            onChange={() => {}}
            fullWidth
            onKeyDown={(e) => {
              if (e.keyCode === 13) handleSalvar();
              else handleKey(e.keyCode, e.key);
            }}
            multiline
            autoFocus
            style={{
              margin: 0,
              padding: '10px',
              border: 'none',
              outline: 'none'
            }}
          /> :
            <div>{content}</div>}
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={handleClose} color="secondary" variant="contained" tabindex="-1">
            {`Cancelar (${CANCELAR_VENDA_ACTION})`}
          </Button>
          <Button onClick={handleSalvar} color="secondary" variant="contained" tabindex="-1">
            {`Confirmar (${FECHAR_VENDA_ACTION})`}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoConfirmacao;
