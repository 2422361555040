import {
  useEffect,
  useRef,
  useState,
} from 'react';

import { useSnackbar } from 'notistack';

import {
  getListAllAPI,
  api,
  imprimirNotaPreVenda,
} from '../services';
import {
  POR_UNIDADE,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  POR_PESO_VARIAVEL,
  POR_PESO_FIXO,
  getSubTotal,
  STATUS_ATIVO,
  getPrecoUnitarioVerdadeiroCaixa,
  getNivelCaixaCodigo,
  analisaCodigo,
  getUnitarioCaixa,
  getPesoCaixa,
  changeCaixariaLocal,
  getTotal2,
  getUnidadesItemCaixa,
  getUnidadesCaixa,
  ZERO_APROXIMADO,
  getValueCaixaria,
  TIPO_UNIDADE_OFERTA_KG,
  TIPO_UNIDADE_OFERTA_UN,
  TIPO_UNIDADE_OFERTA_CAIXA,
  TIPO_UNIDADE_OFERTA_CAIXETA,
  TIPO_UNIDADE_OFERTA_CAIXA_MAE,
} from '../utils';

function useItens(
  itens,
  updateItem,
  cliente,
  sessionId,
  irParaTelaInit,
  itensExcluidos,
  addItensLiberacao,
  modoOffline
) {
  const { enqueueSnackbar } = useSnackbar();
  const refSidebarInputs = useRef(null);
  const [produto, setProduto] = useState(null);
  const [editPrice, setEditPrice] = useState(false);
  const [search, setSearch] = useState('');
  const [subTotal, setSubTotal] = useState('');
  const refSearch = useRef(null);
  const [podeFecharVenda, setPodeFecharVenda] = useState(true);

  useEffect(() => {
    setSubTotal(getSubTotal(itens));
  }, [itens]);

  function addNewItemInterno(dados, produto) {
    const {
      unidades,
      peso,
      unitario,
      cgp
    } = dados;

    const pesoCalculado = getPesoCaixa(produto)

    updateItem({
      produto,
      peso: produto.unidade.tipo === POR_UNIDADE ? 0 : pesoCalculado === 0 ? peso : pesoCalculado * unidades,
      total: getTotal2(peso, unidades, unitario, produto),
      unidades: getUnidadesItemCaixa(produto, unidades),
      unitario: getUnitarioCaixa(produto, unitario),
      uidd: `${produto.nome}${itens.length}`,
      idIndicadorChange: -1,
      unidadesCaixa: getUnidadesCaixa(produto),
      unitarioCaixa: unitario,
      pesoCaixa: pesoCalculado,
      unidadesCaixaAparente: produto.nivelCaixa ? unidades : 0,
      nivelCaixaria: produto.nivelCaixa ? produto.nivelCaixa : 0,
      cgp,
      grupo_oferta_preco_produto_id: null,
      tipo_oferta: null,
      is_margem_cartao: true
    });

    switch (produto.unidade.tipo) {
      case POR_UNIDADE:
        if (produto.unidadesDisponivel < 0) {
          enqueueSnackbar('Estoque insuficiente!', {
            variant: 'warning',
          });
        }
        break;
      case POR_PESO_VARIAVEL_SEM_UNIDADE:
        if (produto.pesoDisponivel < 0) {
          enqueueSnackbar('Estoque insuficiente!', {
            variant: 'warning',
          });
        }
        break;
      case POR_PESO_VARIAVEL:
        if (produto.pesoDisponivel < 0 || produto.unidadesDisponivel < 0) {
          enqueueSnackbar('Estoque insuficiente!', {
            variant: 'warning',
          });
        }
        break;
      case POR_PESO_FIXO:
        if (produto.unidadesDisponivel < 0) {
          enqueueSnackbar('Estoque insuficiente!', {
            variant: 'warning',
          });
        }
        break;
      default:
        break;
    }

    setProduto(null);
    setEditPrice(false);
  }

  function searchItemInArray(produto, itens) {
    let unidadesUsadas = 0;
    let pesoUsado = 0;
    for (let i = 0; i < itens.length; i += 1) {
      if (produto.id === itens[i].produto.id && itens[i].idIndicadorChange < 0) {
        unidadesUsadas += itens[i].unidades;
        pesoUsado += itens[i].peso;
      }
    }

    if (produto.unidade.tipo === POR_UNIDADE) {
      pesoUsado = 0;
    } else if (produto.unidade.tipo === POR_PESO_FIXO) {
      pesoUsado = unidadesUsadas * produto.peso_medio;
    }

    return {
      unidadesUsadas,
      pesoUsado,
    };
  }

  async function searchHandle(codigo, id) {
    try {
      if(!id) {
        const { tipo, data } = analisaCodigo(codigo);
        if (tipo === 0) {
          const { codigo_interno, peso } = data;
          const produtoData = await getListAllAPI('produtos', ['id', 'asc'], {
            codigo_interno, is_kit: false, is_caixa: false, status: STATUS_ATIVO, ofertasProdutos: true
          }, ['unidade']);
          if (produtoData.data.length > 0) {
            if (produtoData.data[0].preco_venda > 0) {
              if (
                produtoData.data[0].unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
                || produtoData.data[0].unidade.tipo === POR_PESO_VARIAVEL
              ) {
                const index = searchItemInArray(produtoData.data[0], itens);
                produtoData.data[0].unidadesDisponivel -= index.unidadesUsadas;
                produtoData.data[0].pesoDisponivel -= index.pesoUsado;
                const hideUnidades = produtoData.data[0].unidade.tipo
                  === POR_PESO_VARIAVEL_SEM_UNIDADE;
                const unidades2 = hideUnidades ? 0 : 1
                addNewItemInterno({
                  unidades: unidades2,
                  peso,
                  unitario: getPrecoUnitarioVerdadeiroCaixa(produtoData.data[0], unidades2, peso)
                }, produtoData.data[0]);
                setProduto(null);
                if (refSearch.current) refSearch.current.focus();
              } else {
                if (refSearch.current) refSearch.current.focus();
                enqueueSnackbar('Este produto não usa balança, deve-se especificar apenas unidades, e não o peso!', {
                  variant: 'error',
                });
              }
            } else {
              if (refSearch.current) refSearch.current.focus();
              enqueueSnackbar('Este produto possui o preço de venda inválido!', {
                variant: 'error',
              });
            }
          } else {
            setProduto(null);
            if (refSearch.current) refSearch.current.focus();
            enqueueSnackbar(`Produto não encotrado com este codigo interno: ${codigo_interno}!`, {
              variant: 'error',
            });
          }
        } else {
          if(tipo === 'cgp') {
            const { codigo_interno, peso } = data;
            const cgp = await api.get(`/conferencias_grandes_pesagens/${codigo_interno}`)
            if(cgp.data.status === STATUS_ATIVO) {
              const produtoData = await getListAllAPI('produtos', ['id', 'asc'], {
                id: cgp.data.produto_id, ofertasProdutos: true
              }, ['unidade']);
              if (produtoData.data.length > 0) {
                if (produtoData.data[0].preco_venda > 0) {
                  if (
                    produtoData.data[0].unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
                    || produtoData.data[0].unidade.tipo === POR_PESO_VARIAVEL
                  ) {
                    const index = searchItemInArray(produtoData.data[0], itens);
                    produtoData.data[0].unidadesDisponivel -= index.unidadesUsadas;
                    produtoData.data[0].pesoDisponivel -= index.pesoUsado;
                    const hideUnidades = produtoData.data[0].unidade.tipo
                      === POR_PESO_VARIAVEL_SEM_UNIDADE;
                    const unidades2 = hideUnidades ? 0 : 1
                    const peso2 = cgp.data.peso_liquido_total
                    addNewItemInterno({
                      unidades: unidades2,
                      peso: peso2,
                      unitario: getPrecoUnitarioVerdadeiroCaixa(produtoData.data[0], unidades2, peso2),
                      cgp: cgp.data.id
                    }, produtoData.data[0]);
                    setProduto(null);
                    if (refSearch.current) refSearch.current.focus();
                  } else {
                    if (refSearch.current) refSearch.current.focus();
                    enqueueSnackbar('Este produto não usa balança, deve-se especificar apenas unidades, e não o peso!', {
                      variant: 'error',
                    });
                  }
                } else {
                  if (refSearch.current) refSearch.current.focus();
                  enqueueSnackbar('Este produto possui o preço de venda inválido!', {
                    variant: 'error',
                  });
                }
              } else {
                setProduto(null);
                if (refSearch.current) refSearch.current.focus();
                enqueueSnackbar(`Produto não encotrado com este codigo interno: ${codigo_interno}!`, {
                  variant: 'error',
                });
              }
            } else {
              if (refSearch.current) refSearch.current.focus();
              enqueueSnackbar('Esta Conferência de Grandes Pesagens já foi utilizada!', {
                variant: 'error',
              });
            }
          }  else {
            const produtoData = await getListAllAPI('produtos', ['id', 'asc'], {
              codigo, is_kit: false, is_caixa: false, status: STATUS_ATIVO, ofertasProdutos: true
            }, ['unidade']);
            if (produtoData.data.length > 0) {
              if (produtoData.data[0].preco_venda > 0) {
                const index = searchItemInArray(produtoData.data[0], itens);
                produtoData.data[0].unidadesDisponivel -= index.unidadesUsadas;
                produtoData.data[0].pesoDisponivel -= index.pesoUsado;
                const hideUnidades = produtoData.data[0].unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE;
                setProduto(produtoData.data[0]);
                if (refSidebarInputs.current) refSidebarInputs.current.focus(hideUnidades ? 1 : 0);
              } else {
                if (refSearch.current) refSearch.current.focus();
                enqueueSnackbar('Este produto possui o preço de venda inválido!', {
                  variant: 'error',
                });
              }
            } else {
              const produtoDataCaixa = await getListAllAPI('produtos/caixa', ['id', 'asc'], { codigo, status: STATUS_ATIVO, ofertasProdutos: true }, ['unidade']);
              if (produtoDataCaixa.data.length > 0) {
                if (produtoDataCaixa.data[0].preco_venda > 0) {
                  const index = searchItemInArray(produtoDataCaixa.data[0], itens);
                  produtoDataCaixa.data[0].unidadesDisponivel -= index.unidadesUsadas;
                  produtoDataCaixa.data[0].pesoDisponivel -= index.pesoUsado;
                  produtoDataCaixa.data[0].nivelCaixa = getNivelCaixaCodigo(produtoDataCaixa.data[0], codigo);
                  const hideUnidades = produtoDataCaixa.data[0].unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE;
                  if (produtoDataCaixa.data[0].nivelCaixa > 0) {
                    setProduto(produtoDataCaixa.data[0]);
                    if (refSidebarInputs.current) refSidebarInputs.current.focus(0);
                  } else {
                    setProduto(null);
                    if (refSearch.current) refSearch.current.focus();
                  }
                } else {
                  if (refSearch.current) refSearch.current.focus();
                  enqueueSnackbar('Este produto possui o preço de venda inválido!', {
                    variant: 'error',
                  });
                }
              } else {
                setProduto(null);
                if (refSearch.current) refSearch.current.focus();
                enqueueSnackbar('Produto com código de barras não cadastrado ou código de barras errado!', {
                  variant: 'error',
                });
              }
            }
          }
        }
      } else {
        const produtoData = await getListAllAPI('produtos', ['id', 'asc'], {
          id, ofertasProdutos: true
        }, ['unidade']);
        if (produtoData.data.length > 0) {
          if (produtoData.data[0].preco_venda > 0) {
            const index = searchItemInArray(produtoData.data[0], itens);
            produtoData.data[0].unidadesDisponivel -= index.unidadesUsadas;
            produtoData.data[0].pesoDisponivel -= index.pesoUsado;
            const hideUnidades = produtoData.data[0].unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE;
            setProduto(produtoData.data[0]);
            if (refSidebarInputs.current) refSidebarInputs.current.focus(hideUnidades ? 1 : 0);
          } else {
            if (refSearch.current) refSearch.current.focus();
            enqueueSnackbar('Este produto possui o preço de venda inválido!', {
              variant: 'error',
            });
          }
        }
      }

      setSearch('');
    } catch(e) {
      enqueueSnackbar('INTERNET: ERRO AO BUSCAR PRODUTO!', {
        variant: 'error',
      });
    }
  }

  function changeCaixaria() {
    changeCaixariaLocal(produto, setProduto, enqueueSnackbar)
  }

  async function searchHandle2(codigo, id) {
    try {
      if(!id) {
        const { tipo, data } = analisaCodigo(codigo);
        if (tipo === 0) {
          const { codigo_interno, peso } = data;
          const produtoData = await getListAllAPI('produtos', ['id', 'asc'], {
            codigo_interno, is_kit: false, is_caixa: false, status: STATUS_ATIVO, ofertasProdutos: true
          }, ['unidade']);
          if (produtoData.data.length > 0) {
            if (produtoData.data[0].preco_venda > 0) {
              if (
                produtoData.data[0].unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
                || produtoData.data[0].unidade.tipo === POR_PESO_VARIAVEL
              ) {
                const index = searchItemInArray(produtoData.data[0], itens);
                produtoData.data[0].unidadesDisponivel -= index.unidadesUsadas;
                produtoData.data[0].pesoDisponivel -= index.pesoUsado;
                const hideUnidades = produtoData.data[0].unidade.tipo
                  === POR_PESO_VARIAVEL_SEM_UNIDADE;
                  const unidades2 = hideUnidades ? 0 : 1
                addNewItemInterno({
                  unidades: unidades2,
                  peso,
                  unitario: getPrecoUnitarioVerdadeiroCaixa(produtoData.data[0], unidades2, peso)
                }, produtoData.data[0]);
                setProduto(null);
                if (refSearch.current) refSearch.current.focus();
              } else {
                if (refSearch.current) refSearch.current.focus();
                enqueueSnackbar('Este produto não usa balança, deve-se especificar apenas unidades, e não o peso!', {
                  variant: 'error',
                });
              }
            } else {
              if (refSearch.current) refSearch.current.focus();
              enqueueSnackbar('Este produto possui o preço de venda inválido!', {
                variant: 'error',
              });
            }
          } else {
            setProduto(null);
            if (refSearch.current) refSearch.current.focus();
            enqueueSnackbar(`Produto não encotrado com este codigo interno: ${codigo_interno}!`, {
              variant: 'error',
            });
          }
        } else {
          const produtoData = await getListAllAPI('produtos', ['id', 'asc'], {
            codigo, is_kit: false, is_caixa: false, status: STATUS_ATIVO, ofertasProdutos: true
          }, ['unidade']);
          if (produtoData.data.length > 0) {
            if (produtoData.data[0].preco_venda > 0) {
              const index = searchItemInArray(produtoData.data[0], itens);
              produtoData.data[0].unidadesDisponivel -= index.unidadesUsadas;
              produtoData.data[0].pesoDisponivel -= index.pesoUsado;
              const hasUnidade = produtoData.data[0].unidade.tipo === POR_UNIDADE || produtoData.data[0].unidade.tipo === POR_PESO_FIXO;

              if (hasUnidade) {
                const peso2 = produtoData.data[0].unidade.tipo === POR_UNIDADE ? 0 : produtoData.data[0].peso_medio
                addNewItemInterno({
                  unidades: 1,
                  peso: peso2,
                  unitario: getPrecoUnitarioVerdadeiroCaixa(produtoData.data[0], 1, peso2)
                }, produtoData.data[0]);
              } else {
                enqueueSnackbar('Esete produto não é vendido na unidade, por isso não pode ser preenchido automaticamente!', {
                  variant: 'error',
                });
              }
              setProduto(null);
              if (refSearch.current) refSearch.current.focus();
            } else {
              if (refSearch.current) refSearch.current.focus();
              enqueueSnackbar('Este produto possui o preço de venda inválido!', {
                variant: 'error',
              });
            }
          } else {
            const produtoDataCaixa = await getListAllAPI('produtos/caixa', ['id', 'asc'], { codigo, status: STATUS_ATIVO, ofertasProdutos: true }, ['unidade']);
            if (produtoDataCaixa.data.length > 0) {
              if (produtoDataCaixa.data[0].preco_venda > 0 ) {
                const index = searchItemInArray(produtoDataCaixa.data[0], itens);
                produtoDataCaixa.data[0].unidadesDisponivel -= index.unidadesUsadas;
                produtoDataCaixa.data[0].pesoDisponivel -= index.pesoUsado;
                produtoDataCaixa.data[0].nivelCaixa = getNivelCaixaCodigo(produtoDataCaixa.data[0], codigo);
                const hasUnidade = produtoDataCaixa.data[0].unidade.tipo === POR_UNIDADE || produtoDataCaixa.data[0].unidade.tipo === POR_PESO_FIXO || produtoDataCaixa.data[0].unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE;
                if (produtoDataCaixa.data[0].nivelCaixa > 0 && hasUnidade) {
                  const peso2 = produtoDataCaixa.data[0].unidade.tipo === POR_UNIDADE ? 0 : getPesoCaixa(produtoDataCaixa.data[0])
                  addNewItemInterno({
                    unidades: 1,
                    peso: peso2,
                    unitario: getPrecoUnitarioVerdadeiroCaixa(produtoData.data[0], 1, peso2)
                  }, produtoDataCaixa.data[0]);
                  setProduto(null);
                  if (refSearch.current) refSearch.current.focus();
                } else {
                  setProduto(null);
                  if (refSearch.current) refSearch.current.focus();
                  enqueueSnackbar('Este produto não possui caixaria!', {
                    variant: 'error',
                  });
                }
              } else {
                if (refSearch.current) refSearch.current.focus();
                enqueueSnackbar('Este produto possui o preço de venda inválido!', {
                  variant: 'error',
                });
              }
            } else {
              setProduto(null);
              if (refSearch.current) refSearch.current.focus();
              enqueueSnackbar('Produto com código de barras não cadastrado ou código de barras errado!', {
                variant: 'error',
              });
            }
          }
        }
      } else {
        const produtoData = await getListAllAPI('produtos', ['id', 'asc'], {
          id, ofertasProdutos: true
        }, ['unidade']);
        if (produtoData.data.length > 0) {
          if (produtoData.data[0].preco_venda > 0) {
            const index = searchItemInArray(produtoData.data[0], itens);
            produtoData.data[0].unidadesDisponivel -= index.unidadesUsadas;
            produtoData.data[0].pesoDisponivel -= index.pesoUsado;
            const hasUnidade = produtoData.data[0].unidade.tipo === POR_UNIDADE || produtoData.data[0].unidade.tipo === POR_PESO_FIXO;

            if (hasUnidade) {
              const peso2 = produtoData.data[0].unidade.tipo === POR_UNIDADE ? 0 : produtoData.data[0].peso_medio
              addNewItemInterno({
                unidades: 1,
                peso: peso2,
                unitario: getPrecoUnitarioVerdadeiroCaixa(produtoData.data[0], 1, peso2)
              }, produtoData.data[0]);
            } else {
              enqueueSnackbar('Esete produto não é vendido na unidade, por isso não pode ser preenchido automaticamente!', {
                variant: 'error',
              });
            }
            setProduto(null);
            if (refSearch.current) refSearch.current.focus();
          } else {
            if (refSearch.current) refSearch.current.focus();
            enqueueSnackbar('Este produto possui o preço de venda inválido!', {
              variant: 'error',
            });
          }
        } 
      }

      setSearch('');
    } catch(e) {
      enqueueSnackbar('INTERNET: ERRO AO BUSCAR PRODUTO!', {
        variant: 'error',
      });
    }
  }

  async function handleCloseDialogoSenha(senha) {
    try {
      const dataConfig = await api.get('/config/1');
      if (senha === dataConfig.data.senha_alterar_preco_frente_caixa) {
        setEditPrice(true);
      } else {
        enqueueSnackbar('Senha incorreta!', {
          variant: 'error',
        });
      }
    } catch(e) {
      enqueueSnackbar('ERRO NO DIALOGO DE SENHA!', {
        variant: 'error',
      });
    }
  }

  function addNewItem(dados) {
    addNewItemInterno(dados, produto);
  }

  function montarObjPreVenda() {
    const listaItens = [];
    const listaItensExcluidos = [];

    for (let i = 0; i < itens.length; i += 1) {
      listaItens.push({
        peso: itens[i].peso,
        unidades: itens[i].unidades,
        preco_venda: itens[i].unitario,
        preco_compra: itens[i].produto.precoCompraMedio,
        produto_id: itens[i].produto.id,
        idIndicadorChange: itens[i].idIndicadorChange,
        unidades_caixa: itens[i].unidadesCaixa,
        preco_venda_caixa: itens[i].unitarioCaixa,
        peso_caixa: itens[i].pesoCaixa,
        unidades_caixa_aparente: itens[i].unidadesCaixaAparente,
        nivel_caixaria: itens[i].nivelCaixaria,
        cgp: itens[i].cgp,
        grupo_oferta_preco_produto_id: itens[i].grupo_oferta_preco_produto_id ? itens[i].grupo_oferta_preco_produto_id : null,
        tipo_oferta: itens[i].tipo_oferta ? itens[i].tipo_oferta : null,
        is_margem_cartao: itens[i].is_margem_cartao === true ? true : false
      });
    }

    for (let i = 0; i < itensExcluidos.length; i += 1) {
      listaItensExcluidos.push({
        peso: itensExcluidos[i].peso,
        unidades: itensExcluidos[i].unidades,
        preco_venda: itensExcluidos[i].unitario,
        preco_compra: itensExcluidos[i].produto.precoCompraMedio,
        produto_id: itensExcluidos[i].produto.id,
        idIndicadorChange: itensExcluidos[i].idIndicadorChange,
        unidades_caixa: itensExcluidos[i].unidadesCaixa,
        preco_venda_caixa: itensExcluidos[i].unitarioCaixa,
        peso_caixa: itensExcluidos[i].pesoCaixa,
        unidades_caixa_aparente: itensExcluidos[i].unidadesCaixaAparente,
        nivel_caixaria: itensExcluidos[i].nivelCaixaria,
      });
    }

    return {
      listaItens,
      listaItensExcluidos,
      data: new Date(),
      cliente_id: cliente ? cliente.id : null,
      sessao_id: sessionId,
      subTotal
    };
  }

  async function submitPreVenda() {
    try {
      const objVenda = montarObjPreVenda();
      const data = await api.post('/pre_vendas/fc', {
        ...objVenda,
      });
      return data.data.id;
    } catch (e) {
      return -1;
    }
  }

  function getMessagesError() {
    const errors = [];
    if (cliente === null) {
      errors.push({
        message: 'Campo cliente não foi preenchido!',
        type: 'warning',
      });
    }
    return errors;
  }

  async function handleFinalizarPreVenda() {
    const errors = getMessagesError();
    if (errors.length <= 0 && podeFecharVenda) {
      setPodeFecharVenda(false);
      const idVenda = await submitPreVenda();
      if (idVenda > 0) {
        const codigo = await imprimirNotaPreVenda(idVenda, modoOffline);
        if (codigo < 0) {
          enqueueSnackbar(
            'Erro ao imprimir a nota!',
            {
              variant: 'error',
            },
          );
        }
        irParaTelaInit();
      } else {
        setPodeFecharVenda(true);
        enqueueSnackbar(
          'Erro ao registrar a pre venda!',
          {
            variant: 'error',
          },
        );
      }
    } else {
      for (let i = 0; i < errors.length; i += 1) {
        enqueueSnackbar(
          errors[i].message,
          {
            variant: errors[i].type,
          },
        );
      }
    }
  }

  useEffect(() => {
    async function focusCerto() { 
      if(produto) {
          const hasUnidade = produto.unidade.tipo === POR_UNIDADE || produto.unidade.tipo === POR_PESO_FIXO || produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE;
          if(hasUnidade) {
            switch (produto.nivelCaixaAntigo) {
              case 1:
                if(produto.unidades_caixa_nivel_2 > 0) {
                  if (refSidebarInputs.current) refSidebarInputs.current.focus(0);
                } else {
                  if(produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
                    if (refSidebarInputs.current) refSidebarInputs.current.focus(1);
                  } else {
                    if (refSidebarInputs.current) refSidebarInputs.current.focus(0);
                  }
                }
                break;
              case 2:
                if(produto.unidades_caixa_nivel_3 > 0) {
                  if (refSidebarInputs.current) refSidebarInputs.current.focus(0);
                } else {
                  if(produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
                    if (refSidebarInputs.current) refSidebarInputs.current.focus(1);
                  } else {
                    if (refSidebarInputs.current) refSidebarInputs.current.focus(0);
                  }
                }
                break;
              case 3:
                if(produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
                  if (refSidebarInputs.current) refSidebarInputs.current.focus(1);
                } else {
                  if (refSidebarInputs.current) refSidebarInputs.current.focus(0);
                }
                break;
              case -1:
                if(produto.unidades_caixa_nivel_1 > 0 || produto.peso_caixa_nivel_1 > ZERO_APROXIMADO) {
                  if (refSidebarInputs.current) refSidebarInputs.current.focus(0);
                } else {
                  if(produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
                    if (refSidebarInputs.current) refSidebarInputs.current.focus(1);
                  } else {
                    if (refSidebarInputs.current) refSidebarInputs.current.focus(0);
                  }
                }
                break;
            }
          }
      }
    }
    focusCerto()
  }, [produto]) 

  function getQuantidades(produto, qtde, tipoUnidadeOferta, precoUnitario) {
    switch (produto.unidade.tipo) {
      case POR_UNIDADE: {
        switch (tipoUnidadeOferta) {
          case TIPO_UNIDADE_OFERTA_UN: {
            return { unidades: qtde, peso: 0, precoCaixa: precoUnitario };
          }
          case TIPO_UNIDADE_OFERTA_CAIXA: {
            return { unidades: qtde, peso: 0, precoCaixa: precoUnitario * produto.unidades_caixa_nivel_1 };
          }
          case TIPO_UNIDADE_OFERTA_CAIXETA: {
            return { unidades: qtde, peso: 0, precoCaixa: precoUnitario * produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 };
          }
          case TIPO_UNIDADE_OFERTA_CAIXA_MAE: {
            return { unidades: qtde, peso: 0, precoCaixa: precoUnitario * produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3 };
          }
          default: {
            return { unidades: qtde, peso: 0, precoCaixa: precoUnitario };
          }
        }
      }
      case POR_PESO_VARIAVEL_SEM_UNIDADE: {
        switch (tipoUnidadeOferta) {
          case TIPO_UNIDADE_OFERTA_KG: {
            return { unidades: 0, peso: qtde, precoCaixa: precoUnitario   };
          }
          case TIPO_UNIDADE_OFERTA_CAIXA: {
            return { unidades: qtde, peso: qtde * produto.peso_caixa_nivel_1, precoCaixa: precoUnitario * produto.peso_caixa_nivel_1 };
          }
          case TIPO_UNIDADE_OFERTA_CAIXETA: {
            return { unidades: qtde, peso: qtde *
              produto.peso_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2, precoCaixa: precoUnitario * produto.peso_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 };
          }
          case TIPO_UNIDADE_OFERTA_CAIXA_MAE: {
            return { unidades: qtde, peso: qtde *
              produto.peso_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3, precoCaixa: precoUnitario * produto.peso_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3 };
          }
          default: {
            return { unidades: qtde, peso: 0, precoCaixa: precoUnitario  };
          }
        }
      }
      case POR_PESO_VARIAVEL: {
        return { unidades: 0, peso: 0, precoCaixa: 0 };
      }
      case POR_PESO_FIXO: {
        switch (tipoUnidadeOferta) {
          case TIPO_UNIDADE_OFERTA_UN: {
            return { unidades: qtde, peso: qtde * produto.peso_medio, precoCaixa: precoUnitario  };
          }
          case TIPO_UNIDADE_OFERTA_CAIXA: {
            return { unidades: qtde, peso: qtde * produto.unidades_caixa_nivel_1 * produto.peso_medio, precoCaixa: precoUnitario * produto.unidades_caixa_nivel_1 * produto.peso_medio  };
          }
          case TIPO_UNIDADE_OFERTA_CAIXETA: {
            return { unidades: qtde, peso: qtde *
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 * produto.peso_medio, precoCaixa: precoUnitario * produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 * produto.peso_medio };
          }
          case TIPO_UNIDADE_OFERTA_CAIXA_MAE: {
            return { unidades: qtde, peso: qtde *
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3 * produto.peso_medio, precoCaixa: precoUnitario * produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3 * produto.peso_medio };
          }
          default: {
            return { unidades: qtde, peso: 0, precoCaixa: precoUnitario };
          }
        }
      }
      default:
        return { unidades: 0, peso: 0, precoCaixa: 0 };
    }
  }

  function handleCarregarLiberacoes(liberacoes) {
    const itensNovos = []
    for(let i = 0; i < liberacoes.length; i++) {
      const produto = {...liberacoes[i].produto, nivelCaixa: getValueCaixaria(liberacoes[i].tipo_unidade)}
      const {
        peso,unidades, precoCaixa
      } = getQuantidades(produto, liberacoes[i].escalonadas[0].quantidade, liberacoes[i].tipo_unidade, liberacoes[i].valor)

      const unitario = precoCaixa
      const pesoCalculado = getPesoCaixa(produto)

      itensNovos.push({
        produto,
        peso: produto.unidade.tipo === POR_UNIDADE ? 0 : pesoCalculado === 0 ? peso : pesoCalculado * unidades,
        total: getTotal2(peso, unidades, unitario, produto),
        unidades: getUnidadesItemCaixa(produto, unidades),
        unitario: getUnitarioCaixa(produto, unitario),
        uidd: `${produto.nome}${itens.length + i}`,
        idIndicadorChange: -1,
        unidadesCaixa: getUnidadesCaixa(produto),
        unitarioCaixa: unitario,
        pesoCaixa: pesoCalculado,
        unidadesCaixaAparente: produto.nivelCaixa ? unidades : 0,
        nivelCaixaria: produto.nivelCaixa ? produto.nivelCaixa : 0,
        cgp: null,
        grupo_oferta_preco_produto_id: liberacoes[i].id,
        tipo_oferta: liberacoes[i].tipo,
        is_margem_cartao: liberacoes[i].is_margem_cartao
      })
    }
    addItensLiberacao(itensNovos)
  }

  return {
    searchHandle,
    searchHandle2,
    handleCloseDialogoSenha,
    addNewItem,
    setSearch,
    editPrice,
    produto,
    subTotal,
    refSearch,
    search,
    refSidebarInputs,
    enqueueSnackbar,
    setProduto,
    handleFinalizarPreVenda,
    podeFecharVenda,
    changeCaixaria,
    handleCarregarLiberacoes
  };
}

export default useItens;
