import React, {
  useImperativeHandle,
  forwardRef,
  useRef,
  useState,
  useContext
} from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import { SECONDARY_COLOR, ATALHO_AVANCAR, ATALHO_VOLTAR, SECONDARY_COLOR_OFFLINE } from '../../../utils';
import {
  TextInput,
} from '../../Inputs';
import DialogoAutorizacaoComQrcode from '../DialogoAutorizacaoComQrcode'
import { AppContext } from '../../../app/context';

const FECHAR_VENDA_ACTION = ATALHO_AVANCAR;
const CANCELAR_VENDA_ACTION = ATALHO_VOLTAR;
const ESC_ACTION = 'Escape';

const DialogoNota = forwardRef(({
  handleCancelar = () => {}, 
  handleSalvar, 
  title = 'titulo', 
  enqueueSnackbar, 
  titleAutorizacao = 'titulo',
  mensagemRemovidoAutorizacao = 'Item removido com sucesso!',
}, ref) => {
  const [open, setOpen] = useState(false);
  const [codigoItem, setCodigoItem] = useState('');
  const refDialogoAutorizacaoComQrcode = useRef(null);

  const { app: { modoOffline } } = useContext(AppContext);

  const handleCloseDialog = () => {
    setOpen(false);
    handleCancelar();
  };

  async function handleSalvarDialog() {
    if (!erroExistente) {
      if (refDialogoAutorizacaoComQrcode.current) {
        refDialogoAutorizacaoComQrcode.current.handleOpen();
      }
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
      setCodigoItem('');
    },
  }));

  function handleKey(action, name) {
    switch (name) {
      case FECHAR_VENDA_ACTION:
        handleSalvarDialog();
        break;
      case CANCELAR_VENDA_ACTION:
        handleCloseDialog();
        break;
      case ESC_ACTION:
        handleCloseDialog();
        break;
      default:
        break;
    }
  }

  function validateNumeroItem() {
    let error = '';
    if (codigoItem.length === 0) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function getErros() {
    const errosOld = [
      '',
    ];
    errosOld[0] = validateNumeroItem();
    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  function limparCodigo(codigo) {
    if (codigo) {
      const codigoSemEspacos = codigo.trim();
      return codigoSemEspacos.replace(/\D/g, '');
    }
    return '';
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle 
          id="form-dialog-title" 
          style={{ 
            color: 'white', 
            backgroundColor: modoOffline ? SECONDARY_COLOR_OFFLINE : SECONDARY_COLOR, 
            textAlign: 'center' 
          }}
        >
          {title}
        </DialogTitle>
        <DialogContent>
          <TextInput
            name='codigo'
            handleEnter={() => {
              handleSalvarDialog()
            }}
            label='Código'
            handleKey={handleKey}
            value={codigoItem}
            onChange={(value) => setCodigoItem(limparCodigo(value.target.value))}
            fullWidth
            autoFocus
            error={erros[0] !== ''}
            helperText={erros[0]}
          />
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            onClick={handleCloseDialog}
            color="secondary"
            variant="contained"
            tabindex="-1"
          >
            {`Cancelar (${CANCELAR_VENDA_ACTION})`}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleSalvarDialog}
            disabled={erroExistente}
            tabindex="-1"
          >
            {`Confirmar (${FECHAR_VENDA_ACTION})`}
          </Button>
        </DialogActions>
        <DialogoAutorizacaoComQrcode
          ref={refDialogoAutorizacaoComQrcode}
          handleClose={() => {}}
          enqueueSnackbar={enqueueSnackbar}
          handleSalvar={(user_id) => {
            handleSalvar(codigoItem, user_id);
            setOpen(false);
          }}
          mensagemSucesso={mensagemRemovidoAutorizacao}
          title={titleAutorizacao}
        />
      </Dialog>
    </div>
  );
});

export default DialogoNota;
