import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useRef,
  useContext
} from 'react';

import {
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  IconButton, 
  InputAdornment, 
  Box,
  Typography
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';

import { api } from '../../../services';
import {
  SECONDARY_COLOR, ATALHO_AVANCAR, ATALHO_VOLTAR, SECONDARY_COLOR_OFFLINE
} from '../../../utils';
import { TextInput } from '../../Inputs';
import { AppContext } from '../../../app/context';

const inputs = [
  {
    nome: 'senha_responsavel',
    defaultValue: '',
    label: 'Senha Responsável',
  },
];

const FECHAR_VENDA_ACTION = ATALHO_AVANCAR;
const CANCELAR_VENDA_ACTION = ATALHO_VOLTAR;
const ESC_ACTION = 'Escape';

const ETAPA_1 = 0
const ETAPA_2 = 1

const DialogoNota = forwardRef(({
  handleSalvar, 
  title, 
  enqueueSnackbar, 
  mensagemSucesso = 'Item removido com sucesso!', 
}, ref) => {
  const [open, setOpen] = useState(false);
  const [textInit, setTextInit] = useState('')
  const [userId, setUserId] = useState(0);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [codigo, setCodigo] = useState(0);
  const [dados, setDados] = useState(null);
  const [etapa, setEtapa] = useState(ETAPA_1 );
  const refControle = useRef(false)

  const { app: { modoOffline } } = useContext(AppContext);

  const refs = useRef([]);

  refs.current = inputs.map(
    (ref, index) => refs.current[index] = React.createRef(),
  );

  const handleCloseDialog = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    handleOpen(codigoNew = 0, dados = null) {
      setOpen(true);
      setCodigo(codigoNew);
      setTextInit('')
      setUserId(0)
      setUsername('')
      setPassword('')
      setDados(dados)
      setEtapa(ETAPA_1)
    },
  }));

  async function handleSalvarDialog() {
    if(!refControle.current) {
      refControle.current = true;
      if (!erroExistente) {
        try {
          const dataConfig = await api.post('/login_retirada_caixa', {
            username,
            password,
          });
          if (dataConfig.data.status) {
            handleSalvar(dataConfig.data.user_id, dados, codigo,);
            setOpen(false);
            enqueueSnackbar(mensagemSucesso, {
              variant: 'success',
            });
          } else {
            enqueueSnackbar('Senha incorreta!', {
              variant: 'error',
            });
          }
        } catch (e) {
          enqueueSnackbar('INTERNET: ERRO AO AUTORIZAR!', {
            variant: 'error',
          });
        } finally {
          refControle.current = false;
        }
      }
    }
  }

  function validateTextInit() {
    let error = '';
    if (!textInit) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validatePassword() {
    let error = '';
    if (!password) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function getErros() {
    const errosOld = [
      ''
    ];
    errosOld[0] = etapa === ETAPA_1 ? validateTextInit() : validatePassword();

    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  async function getDadosQrcode() {
    try {
      const response = await api.post(`/dados_qrcode_gerente_caixa`, {
        hash: textInit.replace(/;/g, '/')
      })
      const { 
        status,
        message,
        data
      } = response.data

      if(status) {
        setTextInit('')
        setUsername(data.username)
        setUserId(data.user_id)
        setEtapa(ETAPA_2)
        setPassword('')
      } else {
        setTextInit('')
        enqueueSnackbar(message, {
          variant: 'error',
        });
      }
      
    } catch(e) {
      setTextInit('')
      enqueueSnackbar('INTERNET: ERRO AO AUTORIZAR COM O QRCODE!', {
        variant: 'error',
      });
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="form-dialog-title" style={{ color: 'white', backgroundColor: modoOffline ? SECONDARY_COLOR_OFFLINE : SECONDARY_COLOR, textAlign: 'center' }}>
          {title}
        </DialogTitle>
        <DialogContent>
          {etapa === ETAPA_1 
            ? 
            <TextInput
              name='qrcode'
              label="Insira aqui o Qrcode"
              value={textInit}
              onChange={(event) => {
                setTextInit(event.target.value);
              }}
              fullWidth
              handleKey={(action, name) => {
                switch (name) {
                  case FECHAR_VENDA_ACTION:
                    getDadosQrcode()
                    break;
                  case CANCELAR_VENDA_ACTION:
                    handleCloseDialog();
                    break;
                  case ESC_ACTION:
                    handleCloseDialog();
                    break;
                  default:
                    break;
                }
              }}
              handleEnter={() => {
                getDadosQrcode()
              }}
              error={erros[0] !== ''}
              helperText={erros[0]}
              autoFocus
            />
            :
            <Box>
              <Typography variant="h6" gutterBottom>
                Gerente
              </Typography>

              <Box display="flex">
                <Box flex={1} mr="0.5em">
                  <TextInput
                    name='username'
                    label="Usuário"
                    value={username}
                    onChange={() => {}}
                    fullWidth
                    handleKey={() => {}}
                    handleEnter={() => {}}
                    disabled
                  />
                </Box>
                <Box flex={1} ml="0.5em">
                  <TextInput
                    ref={refs.current[0]}
                    handleEnter={() => {
                      handleSalvarDialog()
                    }}
                    name='password'
                    type={'password'}
                    label="Senha"
                    value={password}
                    onChange={(event) => {
                      setPassword(event.target.value);
                    }}
                    fullWidth
                    handleKey={(action, name) => {
                      switch (name) {
                        case FECHAR_VENDA_ACTION:
                          handleSalvarDialog()
                          break;
                        case CANCELAR_VENDA_ACTION:
                          handleCloseDialog();
                          break;
                        case ESC_ACTION:
                          handleCloseDialog();
                          break;
                        default:
                          break;
                      }
                    }}
                    error={erros[0] !== ''}
                    helperText={erros[0]}
                    autoFocus
                    endAdornment={(
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                        >
                          {<Visibility />}
                        </IconButton>
                      </InputAdornment>
                      )}
                    labelWidth={70}
                  />
                </Box>
              </Box>
            </Box>
          }
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            onClick={handleCloseDialog}
            color="secondary"
            variant="contained"
            tabindex="-1"
          >
            {`Cancelar (${CANCELAR_VENDA_ACTION})`}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={ etapa === ETAPA_1 ? getDadosQrcode : handleSalvarDialog}
            disabled={erroExistente}
            tabindex="-1"
          >
            {`Confirmar (${FECHAR_VENDA_ACTION})`}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoNota;
