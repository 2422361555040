import React, {
  useEffect, useContext, useRef, useState,
} from 'react';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { useHistory } from 'react-router-dom';

import { Box } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { AppContext } from '../../app/context';
import {
  DialogoContadorCedulas,
  LabelTeclasAtalho,
  LabelCaixa,
  DialogoConfirmacao,
  DialogoConsultorPrecos,
  DialogoSenha,
  DialogoAutorizacaoRetirada,
} from '../../components';
import {
  getCaixaId,
  getSessionId,
  getUsername,
  api,
  logout,
  imprimirRelatorioCaixa,
  imprimirTroco,
  getListAllAPI,
  getVendaCancelada,
  setVendaCancelada,
} from '../../services';
import {
  formatMoeda, ATALHO_OPEN_MENU, ATALHO_AVANCAR, ATALHO_VOLTAR,
  MALOTE_TROCO_OPTION,
  MALOTE_SUBMALOTE_OPTION,
} from '../../utils';
import DialogoTrocoEntrada from './DialogoTrocoEntrada';
import MenuMaisOpcoes from './MenuMaisOpcoes';
import DialogoRetirada from './DialogoRetirada'
import DialogoAutorizacaoComQrcode from '../../components/Dialogos/DialogoAutorizacaoComQrcode'
import DialogoVideosTutoriais from './DialogoVideosTutoriais'

const NOVA_VENDA_ACTION = ATALHO_AVANCAR;
const NOVA_RETIRADA_ACTION = 'F10';
const OPEN_MENU_ACTION = ATALHO_OPEN_MENU;
const LOGOUT_ACTION = ATALHO_VOLTAR;
const CONSULTOR_PRECOS_ACTION = 'F3';
const NOVA_PREVENDA_ACTION = 'F2';
const NOVO_DEPOSITO_ACTION = 'F9';
const ESC_ACTION = 'esc';

const DESCONECTAR_SESSAO_ACTION = 'F11';

const tempoPing = 5 * 1000

const Init = () => {
  const refDialogoAutorizacaoComQrcode = useRef(null);
  const refDialogoAutorizacaoComQrcodeLogout = useRef(null);
  const refDialogoTrocoEntrada = useRef(null);
  const refDialogoSenha = useRef(null);
  const refDialogoRetirada = useRef(null);
  const refDialogoSenha2 = useRef(null);
  const refDialogoVideosTutoriais = useRef(null);
  const refDialogoContadorCedulas = useRef(null);
  const refDialogoConsultorPrecos = useRef(null);
  const history = useHistory();
  const menuRef = useRef(null);
  const dialogoLogoutRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const { dispatch, app: {
     saldoCaixa, 
     mensagemInicialFc, 
     saldoIndicadorRetirada, 
     modoOffline,
     isBuscouDadosPermissoes,
     criarVendaPermissao,
      criarPedidoPermissao,
      criarRetiradaPermissao,
      consultarPrecosPermissao,
      utilizarTrocoPermissao,
      tipoCaixa,
      nomeCaixa
  } } = useContext(AppContext);
  const [responsavelId, setResponsavelId] = useState(0);

  const refControleRetirada = useRef(false)
  const refControleDeposito = useRef(false)
  const refControleLogout = useRef(false)

  async function getPermissoes() {
    try {
      const response = await api.get(`permissoes_frente_caixa/${getSessionId()}`);
      const dados = formatPermissoes(response.data)
      dispatch({ 
        type: 'UPDATE_PERMISSOES', 
        criarVendaPermissao: dados.criarVendaPermissao,
        consultarPrecosPermissao: dados.consultarPrecosPermissao,
        utilizarTrocoPermissao: dados.utilizarTrocoPermissao,
        utilizarTrocoAberturaCaixaPermissao: dados.utilizarTrocoAberturaCaixaPermissao,
        criarPedidoPermissao: dados.criarPedidoPermissao,
        criarRetiradaPermissao: dados.criarRetiradaPermissao,
        carregarPedidoPermissao: dados.carregarPedidoPermissao,
        criarClientePermissao: dados.criarClientePermissao,
        editarClientePermissao: dados.editarClientePermissao,
        tipoPagamentoDinheiroPermissao: dados.tipoPagamentoDinheiroPermissao,
        tipoPagamentoCartaoCreditoPermissao: dados.tipoPagamentoCartaoCreditoPermissao,
        tipoPagamentoCartaoDebitoPermissao: dados.tipoPagamentoCartaoDebitoPermissao,
        tipoPagamentoPixQrcodePermissao: dados.tipoPagamentoPixQrcodePermissao,
        tipoPagamentoVoucherPermissao: dados.tipoPagamentoVoucherPermissao,
        tipoPagamentoDevolucaoPermissao: dados.tipoPagamentoDevolucaoPermissao,
        tipoPagamentoPixDistanciaPermissao: dados.tipoPagamentoPixDistanciaPermissao,
        tipoPagamentoCreditoFidelidadePermissao: dados.tipoPagamentoCreditoFidelidadePermissao,
        pesquisaPorNomes: dados.pesquisaPorNomes,
        tipoCaixa: dados.tipoCaixa,
        nomeCaixa: dados.nomeCaixa,
      })
    } catch (e) {
      enqueueSnackbar(
        'INTERNET: ERRO AO OBTER AS PERMISSÕES!',
        {
          variant: 'error',
        },
      );
    }
  }

  function formatPermissoes(permissoes) {
    const permissoesInit = {
      criarVendaPermissao: false,
      consultarPrecosPermissao: false,
      utilizarTrocoPermissao: false,
      utilizarTrocoAberturaCaixaPermissao: false,
      criarPedidoPermissao: false,
      criarRetiradaPermissao: false,
      carregarPedidoPermissao: false,
      criarClientePermissao: false,
      editarClientePermissao: false,
      tipoPagamentoDinheiroPermissao: false,
      tipoPagamentoCartaoCreditoPermissao: false,
      tipoPagamentoCartaoDebitoPermissao: false,
      tipoPagamentoPixQrcodePermissao: false,
      tipoPagamentoVoucherPermissao: false,
      tipoPagamentoDevolucaoPermissao: false,
      tipoPagamentoPixDistanciaPermissao: false,
      tipoPagamentoCreditoFidelidadePermissao: false,
      pesquisaPorNomes: false,
      tipoCaixa: '',
      nomeCaixa: '',
    };

    if (permissoes) {
      permissoesInit.criarVendaPermissao = permissoes.criar_venda;
      permissoesInit.consultarPrecosPermissao = permissoes.consultar_precos;
      permissoesInit.utilizarTrocoPermissao = permissoes.utilizar_troco;
      permissoesInit.utilizarTrocoAberturaCaixaPermissao = permissoes.utilizar_troco_abertura_caixa;
      permissoesInit.criarPedidoPermissao = permissoes.criar_pedido;
      permissoesInit.criarRetiradaPermissao = permissoes.criar_retirada;
      permissoesInit.carregarPedidoPermissao = permissoes.carregar_pedido;
      permissoesInit.criarClientePermissao = permissoes.criar_cliente;
      permissoesInit.editarClientePermissao = permissoes.editar_cliente;
      permissoesInit.tipoPagamentoDinheiroPermissao = permissoes.tipo_pagamento_dinheiro;
      permissoesInit.tipoPagamentoCartaoCreditoPermissao = permissoes.tipo_pagamento_cartao_credito;
      permissoesInit.tipoPagamentoCartaoDebitoPermissao = permissoes.tipo_pagamento_cartao_debito;
      permissoesInit.tipoPagamentoPixQrcodePermissao = permissoes.tipo_pagamento_pix_qrcode;
      permissoesInit.tipoPagamentoVoucherPermissao = permissoes.tipo_pagamento_voucher;
      permissoesInit.tipoPagamentoDevolucaoPermissao = permissoes.tipo_pagamento_devolucao;
      permissoesInit.tipoPagamentoPixDistanciaPermissao = permissoes.tipo_pagamento_pix_distancia;
      permissoesInit.tipoPagamentoCreditoFidelidadePermissao = permissoes.tipo_pagamento_credito_fidelidade;
      permissoesInit.pesquisaPorNomes = permissoes.pesquisa_por_nomes;
      permissoesInit.tipoCaixa = permissoes.nome;
      permissoesInit.nomeCaixa = permissoes.nomeCaixa;
    }

    return permissoesInit;
  }

  useEffect(() => {
    const timer = setInterval(() => {
      if(!isBuscouDadosPermissoes) {
        getPermissoes()
      }
    }, tempoPing);
    return () => clearInterval(timer);
  });

  useEffect(() => {
    getPermissoes()
  }, []);

  function updateSaldo() {
    dispatch({
      type: 'UPDATE_SALDO_CAIXA',
    });
  }

  async function registraVendaCancelada() {
    const venda = getVendaCancelada()
    if(venda) {
      await api.post('/ratoeira', venda)
      setVendaCancelada(null)
    }
  }

  useEffect(() => {
    try {
      registraVendaCancelada();
    } catch(e) {
      enqueueSnackbar(
        'INTERNET: ERRO AO REGISTRAR VENDA CANCELADA NA RATOEIRA!',
        {
          variant: 'error',
        },
      );
    }
  }, []);

  useEffect(() => {
    updateSaldo();
  }, []);

  function changeModoOffline() {
    dispatch({ type: 'CHANGE_MODO_OFFLINE', modoOffline: !modoOffline })
  }

  async function novaRetirada(valor, objCedulas, userId, transportador_id) {
    if(!refControleRetirada.current) {
      refControleRetirada.current = true
      try {
        const transferencia = await api.post('/malote_sessoes/retirada', {
          nome: '',
          valor,
          sessao_id: getSessionId(),
          conta_id: getCaixaId(),
          categoria_transacao_2_id: null,
          status: true,
          tipo: 'Transferência',
          conta_destino_id: 1,
          repetir: false,
          vezesRepetir: 1,
          tipoPeriodo: 'Mensal',
          notas: objCedulas,
          responsavel_id: userId,
          transportador_id
        });
        updateSaldo();
        setResponsavelId(0);
        const codigo = await imprimirTroco(transferencia.data.id, modoOffline);
        if (codigo < 0) {
          enqueueSnackbar(
            'Erro ao imprimir a nota da retirada!',
            {
              variant: 'error',
            },
          );
        }
      } catch (erros) {
        enqueueSnackbar('INTERNET: ERRO AO REGISTRAR A RETIRADA!', {
          variant: 'error',
        });
      } finally {
        refControleRetirada.current = false
      }
    }
  }

  function handleActions(action) {
    switch (action) {
      case NOVA_VENDA_ACTION:
        if (criarVendaPermissao) history.push('/vendas/frentedecaixa');
        break;
      case NOVA_PREVENDA_ACTION:
        if (criarPedidoPermissao) history.push('/pre_vendas/frentedecaixa');
        break;
      case NOVA_RETIRADA_ACTION:
        if (criarRetiradaPermissao) {
          if (refDialogoRetirada.current) {
            refDialogoRetirada.current.handleOpen();
          }
        }
        break;
      case CONSULTOR_PRECOS_ACTION:
        if (consultarPrecosPermissao) {
          if (refDialogoConsultorPrecos.current) {
            refDialogoConsultorPrecos.current.handleOpen();
          }
        }
        break;
      case OPEN_MENU_ACTION:
        if (refDialogoVideosTutoriais.current) {
          refDialogoVideosTutoriais.current.handleOpen();
        }
        break;
      case NOVO_DEPOSITO_ACTION:
        if (utilizarTrocoPermissao) {
          if (refDialogoTrocoEntrada.current) {
            refDialogoTrocoEntrada.current.handleOpen();
          }
        }
        break;
      case LOGOUT_ACTION:
        if (refDialogoAutorizacaoComQrcodeLogout.current) refDialogoAutorizacaoComQrcodeLogout.current.handleOpen('Encerrar sessão!', 'Deseja realmente encerrar a sessão?', 1);
        break;
      case DESCONECTAR_SESSAO_ACTION:
        if (refDialogoAutorizacaoComQrcode.current) refDialogoAutorizacaoComQrcode.current.handleOpen(0);
        break;
      case ESC_ACTION:
        if (refDialogoAutorizacaoComQrcodeLogout.current) refDialogoAutorizacaoComQrcodeLogout.current.handleOpen('Encerrar sessão!', 'Deseja realmente encerrar a sessão?', 1);
        break;
      default:
        break;
    }
  }

  async function logoutTaskExec() {
    if(!refControleLogout.current) {
      refControleLogout.current = true
      try {
        await api.put(`/malote_sessoes/fechar/${getSessionId()}`);
        logout();
        const codigo = await imprimirRelatorioCaixa(getSessionId(), modoOffline);
        if (codigo < 0) {
          enqueueSnackbar(
            'Erro ao imprimir o relatório!',
            {
              variant: 'error',
            },
          );
        }
        history.push('/login');
      } catch(e) {
          enqueueSnackbar(
            'INTERNET: ERRO AO FECHAR A SESSÃO!',
            {
              variant: 'error',
            },
          );
      } finally {
        refControleLogout.current = false
      }
    }
  }

  function logoutTask() {
    if (saldoCaixa > 2 || saldoCaixa < -2) {
      refDialogoSenha2.current.handleOpen(0);
    } else if (refDialogoSenha2.current) {
      logoutTaskExec();
    }
  }

  async function handleTrocoEntrada(codigo, user_id, transportador_id) {
    if(!refControleDeposito.current) {
      refControleDeposito.current = true
      try {
        const data = await getListAllAPI(
          'malote_pacotes',
          ['id', 'asc'],
          { codigo },
          [],
        );
        if (data.data.length > 0) {
          if (data.data.tipo === MALOTE_TROCO_OPTION || data.data.tipo === MALOTE_SUBMALOTE_OPTION) {
            throw 'Não existe um troco ou submalote com este código!';
          }
          const troco = await api.post('/malote_sessoes/deposito', {
            user_id, 
            transportador2_id: transportador_id,
            troco_id: data.data[0].id,
            sessao_id: getSessionId(),
            conta_id: getCaixaId(),
          });
          const codigo = await imprimirTroco(troco.data.idTroco, modoOffline);
          if (codigo < 0) {
            enqueueSnackbar(
              'Depósito feito com sucesso!',
              {
                variant: 'success',
              },
            );
          }
          if (codigo < 0) {
            enqueueSnackbar(
              'Erro ao imprimir depósito!',
              {
                variant: 'error',
              },
            );
          }
          updateSaldo();
        } else {
          enqueueSnackbar(
            'Este troco não existe ou já foi usado!',
            {
              variant: 'error',
            },
          );
        }
      } catch (erros) {
        enqueueSnackbar('INTERNET: ERRO AO UTILIZAR O TROCO!', {
          variant: 'error',
        });
      } finally {
        refControleDeposito.current = false
      }
    }
  }

  function getLabelsAtalhos() {
    const labelsAtalhos = [];

    if (criarPedidoPermissao) labelsAtalhos.push({ atalho: NOVA_PREVENDA_ACTION, label: 'Novo pedido' });
    if (consultarPrecosPermissao) labelsAtalhos.push({ atalho: CONSULTOR_PRECOS_ACTION, label: 'Cons. Preço' });
    labelsAtalhos.push({ atalho: LOGOUT_ACTION, label: 'Logout' });
    if (criarVendaPermissao) labelsAtalhos.push({ atalho: NOVA_VENDA_ACTION, label: 'Nova venda' });
    if (utilizarTrocoPermissao) labelsAtalhos.push({ atalho: NOVO_DEPOSITO_ACTION, label: 'Novo Depósito' });
    if (criarRetiradaPermissao) labelsAtalhos.push({ atalho: NOVA_RETIRADA_ACTION, label: 'Nova retirada' });
    labelsAtalhos.push({ atalho: DESCONECTAR_SESSAO_ACTION, label: 'Desconectar' });
    labelsAtalhos.push({ atalho: OPEN_MENU_ACTION, label: 'Ajuda' });

    if (labelsAtalhos.length <= 4) {
      return [
        labelsAtalhos,
        [],
      ];
    }
    return [
      labelsAtalhos.slice(0, 4),
      labelsAtalhos.slice(4),
    ];
  }

  const mensagemInicialFcFormatada = mensagemInicialFc.replace(/\$USER/g, getUsername())

  return (
    <>
      <Box margin="10px" />
      <Box display="flex" justifyContent="space-between" padding="10px">
        <Box flex={6}>
          <div style={{
            height: '100% ', width: '100%', display: 'flex', justifyContent: 'center',
          }}
          >
            <h4 style={{
              color: 'white', fontSize: '25px', maxWidth: '40vw', textAlign: 'center',
            }}
            >
              {`${mensagemInicialFcFormatada}`}
            </h4>
          </div>
        </Box>
      </Box>
      <Box margin="10px">
        <div style={{
          display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end',
        }}
        >
          <div style={{ width: '25vw', height: '100%' }}>
            <LabelCaixa tipoCaixa={tipoCaixa} nomeCaixa={nomeCaixa}/>
          </div>
          <div style={{ width: '25vw', height: '100%' }}>
            <LabelTeclasAtalho
              fontSize="15px"
              grid={getLabelsAtalhos()}
            />
          </div>
        </div>
      </Box>
      <MenuMaisOpcoes
        ref={menuRef}
        saldoIndicadorRetirada={saldoIndicadorRetirada}
        changeModoOffline={changeModoOffline}
      />
      <DialogoContadorCedulas
        ref={refDialogoContadorCedulas}
        handleClose={() => updateSaldo()}
        handleSalvar={novaRetirada}
        getTitle={(total) => `Nova retirada, Total = ${formatMoeda(total)}`}
      />
      <DialogoConfirmacao
        ref={dialogoLogoutRef}
        handleConfirma={() => logoutTask()}
        isInput
      />
      <DialogoTrocoEntrada
        ref={refDialogoTrocoEntrada}
        handleSalvar={handleTrocoEntrada}
      />
      <DialogoRetirada
        ref={refDialogoRetirada}
        handleSalvar={novaRetirada}
        handleClose={() => updateSaldo()}
        getTitle={(total) => `Nova retirada, Total = ${formatMoeda(total)}`}
      />
      <DialogoAutorizacaoRetirada
        ref={refDialogoSenha}
        handleClose={() => {}}
        enqueueSnackbar={enqueueSnackbar}
        handleCancelar={() => {}}
        handleSalvar={(user_id) => {
          setResponsavelId(user_id);
          if (refDialogoContadorCedulas.current) {
            refDialogoContadorCedulas.current.handleOpen();
          }
        }}
        mensagemSucesso="Retirada autorizada!"
        title="Autorização retirada!"
      />
      <DialogoSenha
        ref={refDialogoSenha2}
        handleClose={() => {}}
        enqueueSnackbar={enqueueSnackbar}
        handleCancelar={() => {}}
        handleSalvar={(codigo) => {
          logoutTaskExec();
        }}
        mensagemSucesso="Logout realizado!"
        title="Autorização logout!"
      />
      <DialogoAutorizacaoComQrcode
        ref={refDialogoAutorizacaoComQrcode}
        handleClose={() => {}}
        enqueueSnackbar={enqueueSnackbar}
        handleSalvar={(user_id) => {
          logout();
          history.push('/login');
        }}
        mensagemSucesso="Desconexão da sessão realizada com sucesso!"
        title="Desconectar Sessão!"
      />
      <DialogoAutorizacaoComQrcode
        ref={refDialogoAutorizacaoComQrcodeLogout}
        handleClose={() => {}}
        enqueueSnackbar={enqueueSnackbar}
        handleSalvar={(user_id) => {
          logoutTaskExec();
        }}
        mensagemSucesso="Logout realizado!"
        title="Autorização logout!"
      />
      <DialogoConsultorPrecos ref={refDialogoConsultorPrecos} />
      <DialogoVideosTutoriais ref={refDialogoVideosTutoriais} />
      <KeyboardEventHandler
        handleKeys={[
          NOVA_VENDA_ACTION,
          NOVA_RETIRADA_ACTION,
          OPEN_MENU_ACTION,
          LOGOUT_ACTION,
          CONSULTOR_PRECOS_ACTION,
          NOVA_PREVENDA_ACTION,
          NOVO_DEPOSITO_ACTION,
          DESCONECTAR_SESSAO_ACTION,
          ESC_ACTION
        ]}
        onKeyEvent={(key) => handleActions(key)}
      />
    </>
  );
};

export default Init;
